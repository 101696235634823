<template>
  <b-card class="permission-list px-2">
    <!-- search input -->
    <div v-if="roleName" class="mb-2">
      <b-form-group label-for="role-name">
        <b-form-input
          id="role-name"
          :value="roleName"
          placeholder="Tên role"
          @input="(val) => $emit('update:role-name', val)"
        />
      </b-form-group>
    </div>
    <div class="d-flex mb-1">
      <b-input-group>
        <b-input-group-prepend is-text>
          <feather-icon icon="SearchIcon" />
        </b-input-group-prepend>
        <b-form-input v-model.lazy="searchword" placeholder="Tìm kiếm" @input="search" />
      </b-input-group>
      <div v-if="!readonly" class="ml-1">
        <b-overlay :show="loading" block opacity="0.6" spinner-variant="primary">
          <b-button variant="primary" class="text-nowrap" @click="handleClick">
            <span>{{ $t('Update') }}</span>
          </b-button>
        </b-overlay>
      </div>
    </div>
    <!-- tree -->
    <v-tree
      ref="tree"
      class="list-permission"
      :data="data"
      :draggable="true"
      :halfcheck="true"
      :multiple="true"
      :tpl="tpl"
    />
  </b-card>
</template>

<script>
import { VTree } from 'vue-tree-halower';
import { ref, toRefs, watch } from '@vue/composition-api';
import {
  BOverlay,
  BFormInput,
  BInputGroupPrepend,
  BInputGroup,
  BCard,
  BButton,
  BFormGroup,
} from 'bootstrap-vue';
import { permissionList } from '@/apis/apiRole_Permission';
import permissionsMeta from '@/constants/permissions';

export default {
  components: {
    VTree,
    BOverlay,
    BCard,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BButton,
  },
  props: {
    // [{
    //    "id": "93dcaae4-bfd7-47d8-aa89-fe118e89fb44",
    //    "permissionId": "93dcaae4-bfd7-47d8-aa89-fe118e89fb44",
    //    "limited": "owner", // owner | team | all
    //    "name": "Thêm quyền hạn cho nhân viên",
    //    "group": "staffs",
    // }],
    perCheck: {
      type: Array,
      default: null,
    },
    roleName: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    permissionsData: {
      type: Array,
      default: null,
    },
    limited: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // danh sach permission cua he thong
    const { perCheck } = toRefs(props);
    const data = ref([]);
    const permissions = ref(props.permissionsData || []);
    const groupPermission = (listPer) => {
      const res = [];
      const formatArrayPer = listPer?.reduce((result, item) => {
        result[item.group] = result[item.group] || [];
        result[item.group].push(item);
        return result;
      }, {});
      if (formatArrayPer) {
        for (const [key, value] of Object.entries(formatArrayPer)) {
          res.push({
            group: key,
            value,
          });
        }
      }
      return res;
    };

    const createChildren = (listChild, perRole) => {
      const res = [];
      listChild.forEach((child) => {
        const role = perRole.find((r) => r.permissionId === child.id) || {};
        const note = {
          title: child.name,
          checked: !!role.id,
        };
        if (props.limited) {
          note.children = [
            {
              id: `owner_${child.id}`,
              checked: role.limited === 'owner',
              title: 'Cá nhân',
            },
            {
              id: `team_${child.id}`,
              checked: role.limited === 'team',
              title: 'Nhóm',
            },
            {
              id: `all_${child.id}`,
              checked: role.limited === 'all',
              title: 'Tất cả',
            },
          ];
        }

        res.push(note);
      });
      return res;
    };

    const updatePermissionsCheck = (value) => {
      data.value = [];
      // permission cua role
      value = groupPermission(value);
      permissions.value.forEach((item) => {
        const perRole = value.find((val) => val.group === item.group) || {};
        // permission duoc check
        if (perRole.group) {
          data.value.push({
            title: item.group,
            checked: perRole.value.length === item.value.length,
            halfcheck: perRole.value.length < item.value.length,
            children: createChildren(item.value, perRole.value),
            expanded: props.expanded,
          });
        } else {
          // permission khong duoc check
          data.value.push({
            title: item.group,
            children: createChildren(item.value, []),
          });
        }
      });
    };

    const getPermission = () => {
      permissionList()
        .then((res) => {
          permissions.value = groupPermission(res.data.data);

          updatePermissionsCheck(perCheck.value);
        })
        .catch((err) => console.log(err));
    };

    // fetch list permissions
    if (props.permissionsData && props.permissionsData.length) {
      console.log('use existed');
      permissions.value = groupPermission(props.permissionsData);
      updatePermissionsCheck(props.permissionsData);
    } else {
      getPermission();
    }

    watch(perCheck, (value) => {
      updatePermissionsCheck(value);
    });

    return {
      data,
      permissions,
      getPermission,
      groupPermission,
      createChildren,
      permissionsMeta,
    };
  },
  data() {
    return {
      searchword: '',
    };
  },
  methods: {
    handleClick() {
      // let data = [];
      let arrayPermissionCheck = this.$refs.tree.getCheckedNodes();
      arrayPermissionCheck = arrayPermissionCheck.reduce((filter, per) => {
        if (per.id) {
          const item = { id: per.id.split('_')[1], scope: per.id.split('_')[0] };
          filter.push(item);
        }
        return filter;
      }, []);
      this.$emit('update-permission', { permissions: arrayPermissionCheck });
    },
    search() {
      this.$refs.tree.searchNodes(this.searchword);
    },
    tpl(...args) {
      const { 0: node, 2: parent, 3: index, 4: props } = args;
      let titleClass = node.checked ? 'node-title node-selected' : 'node-title';
      if (node.searched) titleClass += ' node-searched';
      if (props.level === 1 && node.checked === true && node.children?.length) {
        node.children.forEach((child) => {
          if (child.children && child.children[0].checked === true) {
            child.children.forEach((item, idx) => {
              if (idx !== 0) {
                item.checked = false;
              }
            });
          }
        });
      }
      if (props.level === 3 && node.checked === true) {
        parent.children.forEach((element, idx) => {
          if (index !== idx) {
            element.checked = false;
          }
        });
        parent.checked = true;
      }
      const title = this.$t(node.title);
      return (
        <span>
          <span
            class={titleClass}
            domPropsInnerHTML={title}
            // onClick={() => {
            //   this.$refs.tree.nodeSelected(node);
            // }}
          ></span>
        </span>
      );
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/tree.scss';
.permission-list {
  .card-body {
    padding-left: 0;
    padding-right: 0;
  }
  .list-permission {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .node-title {
      padding: 0;
      margin: 0;
    }
    .halo-tree {
      .node-title {
        padding: 0;
        margin: 0;
      }
      .check {
        left: -2px;
      }
      li::before {
        display: block;
      }
      li::after {
        display: block;
        width: 7px;
      }
      li {
        width: 100%;
      }
      // .leaf {
      //   display: inline-block;
      //   width: 33%;
      // }
    }
    li::before,
    li::after {
      display: none;
    }
    li {
      width: 30%;
      padding-left: 0;
      margin-left: 15px;
    }
    .inputCheck {
      &.box-checked:after {
        background-color: $primary;
        color: #fff;
      }
    }
  }
}
</style>
